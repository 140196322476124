import api from "./api";
import { DateTime } from "luxon";
import { DataType, EnvironmentalType } from "../enum/EnvironmentalType";
import { CancelToken } from "axios";
import { IStationRegionId } from "../types/IStationRegion";

export interface IGetEnvironmentalDataMeteogramParams {
  station_id: number;
  environmental_type: string;
  macro_region_origin: string;
  dataInterval?: number;
  create_at?: string;
  cancelToken?: CancelToken;
}
export default class AtmOceanService {
  async getHighChartSeriesEnvironmentalData(
    station_id: number,
    data_type: string,
    environmental_type: string,
    environmental_variable: string,
    create_at?: string,
    days_measured?: number
  ) {
    const result = await api.post(
      "/v1/atmOcean/getLineChartEnvironmentalData",
      {
        station_id,
        data_type,
        environmental_type,
        environmental_variable,
        create_at,
        days_measured,
      }
    );
    return result.data;
  }

  async getEnvironmentalDataCSV(
    station_id: number,
    data_type: string,
    environmental_type: string,
    create_at?: string
  ) {
    await api
      .post(
        "/v1/atmOcean/getEnvironmentalDataCSV",
        {
          station_id,
          data_type,
          environmental_type,
          create_at,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response) {
          let contentDisposition = response.request.getResponseHeader(
            "Content-Disposition"
          );
          const filename = contentDisposition.substring(
            contentDisposition.indexOf("filename=") + 9
          );

          const date = DateTime.local().toFormat("yyyy-MM-dd_HHmmss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}_${date}.csv`);
          document.body.appendChild(link);
          link.click();
        }
      });

    return "";
  }

  async getEnvironmentalDataMeteogram(
    getEnvironmentalDataMeteogramParams: IGetEnvironmentalDataMeteogramParams
  ) {
    const {
      station_id,
      environmental_type,
      macro_region_origin,
      dataInterval,
      create_at,
    } = getEnvironmentalDataMeteogramParams;
    const result = await api.post(
      "/v1/atmOcean/getEnvironmentalDataMeteogram",
      {
        station_id,
        environmental_type,
        macro_region_origin,
        data_interval: dataInterval,
        create_at,
      },
      { cancelToken: getEnvironmentalDataMeteogramParams.cancelToken }
    );
    return result.data;
  }

  async getEnvironmentalDataMeteogramNetCDF(
    latitude: number,
    longitude: number,
    environmental_type: string,
    macro_region_origin: string,
    dataInterval?: number,
    controller?: AbortController,
    stationId?: number
  ) {
    const result = await api.post(
      "/v1/atmocean/getEnvironmentalDataMeteogramNetCDF",
      {
        latitude,
        longitude,
        environmental_type,
        macro_region_origin,
        data_interval: dataInterval,
        station_id: stationId,
      },
      { signal: controller?.signal }
    );
    return result.data;
  }

  async getForecastUpdate(
    macro_region_origin: string,
    environmental_type: string
  ): Promise<{
    lastUpdatedAt?: string;
    nextUpdatedAt?: string;
  }> {
    const result = await api.get(
      `/v1/atmocean/get-forecast-update/${macro_region_origin}/${environmental_type}`
    );
    return result.data;
  }

  async getStationsMeteogram(
    environmental_type: EnvironmentalType,
    data_type: DataType = DataType.FORECAST
  ) {
    const result = await api.post("/v1/atmOcean/getStationsMeteogram", {
      environmental_type,
      data_type,
    });
    return result.data;
  }

  async getStationsForecastChart(environmental_type: EnvironmentalType) {
    const result = await api.post("/v1/atmOcean/getStationsForecastChart", {
      environmental_type,
    });
    return result.data;
  }

  async loadRiverStations(macroRegionOrigin?: string) {
    const macroRegionCompletion = macroRegionOrigin
      ? `macro_region_origin=${macroRegionOrigin}`
      : "";

    const result = await api.get(
      `/v1/atmocean/load-river-stations?${macroRegionCompletion}`
    );
    return result.data;
  }

  async loadSensorRiverStations(macroRegionOrigin?: string) {
    const macroRegionCompletion = macroRegionOrigin
      ? `macro_region_origin=${macroRegionOrigin}`
      : "";

    const result = await api.get(
      `/v1/atmocean/load-river-stations-measured-data?${macroRegionCompletion}`
    );
    return result.data;
  }

  async saveStationByUser(
    description: string,
    latitude: number,
    longitude: number,
    macro_region_origin: string
  ) {
    const result = await api.post("/v1/atmocean/saveStationByUser", {
      description,
      latitude,
      longitude,
      macro_region_origin,
    });
    return result.data;
  }

  async hasStationDescription(
    description: string,
    macro_region_origin: string
  ) {
    const result = await api.post("/v1/atmOcean/hasStationDescription", {
      description,
      macro_region_origin,
    });
    return result.data;
  }

  async getGeoLocationClickMap() {
    const result = await api.post("/v1/atmOcean/getGeoLocationClickMap");
    return result.data;
  }

  async removeStationByUser(station_id: number, region_id: number) {
    const result = await api.post("/v1/atmocean/removeStationByUser", {
      station_id,
      region_id,
    });
    return result.data;
  }

  async getStationWithInsight(): Promise<IStationRegionId[]> {
    const result = await api.get("/v1/atmocean/getStationsWithInsights");
    return result.data;
  }
}
