import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoginForm } from "../../../components/Login/LoginForm/LoginForm";
import { NewPasswordForm } from "../../../components/Login/NewPasswordForm/NewPasswordForm";
import { NewPasswordSaved } from "../../../components/Login/NewPasswordSaved/NewPasswordSaved";
import { RecoverPasswordForm } from "../../../components/Login/RecoverPasswordForm/RecoverPasswordForm";
import { LoginLayout } from "../../../components/Login/LoginLayout/LoginLayout";
import { useStores } from "../../../stores/setup/use-store";
import api from "../../../services/api";
import { observer } from "mobx-react-lite";
import { StoreState } from "../../../enum/StoreState";
import { PermissionGroup } from "../../../permissions";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
  const { dataStores } = useStores();

  const { t } = useTranslation("translation", { keyPrefix: "login.login" });

  const {
    dataStores: { authStore, securityStore },
    uiStores: { menuViewStore, navigatorAndWindowStore },
  } = useStores();

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    rememberMe: false,
    email: "",
    error: false,
  });

  const [newPasswordInfo, setNewPasswordInfo] = useState({
    verificationCode: "",
    password: "",
  });

  const [customErrorMessage, setCustomErrorMessage] = useState<string>();

  let history = useHistory();

  useEffect(() => {
    setUserInfo({
      username: "",
      password: "",
      rememberMe: false,
      email: "",
      error: false,
    });
    setNewPasswordInfo({
      verificationCode: "",
      password: "",
    });
    menuViewStore.reset();
    authStore.reset();
    dataStores.resetDataStores();
    clearErrors();
    securityStore.clearTokenSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleFormLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    clearErrors();
    authStore.setState(StoreState.PEDDING);
    const { username, password, rememberMe } = userInfo;

    if (!username || !password) {
      authStore.setState(StoreState.ERROR);
    } else {
      try {
        const response = await api.post("/v1/auth/login", {
          user: username,
          password: password,
          keep_connected: rememberMe,
        });

        const { access_token, refresh_token, profileData, authorization } =
          response.data;

        if (authorization.superUser) {
          authStore.setSuperUser(true);
        }
        const groups: string[] = authorization.groups;
        if (groups.find((group) => group === PermissionGroup.USER_ADMIN)) {
          authStore.setAdminUser(true);
        }

        if (groups.find((group) => group === PermissionGroup.USER_READ_ONLY)) {
          authStore.setReadOnlyUser(true);
        }

        authStore.setUserMacroRegions(authorization?.macroregions || []);

        securityStore.setTokenSet({
          token: access_token,
          refreshToken: refresh_token,
        });
        history.push("/forecast-charts");
        authStore.setState(StoreState.DONE);

        if (profileData.companyId) {
          authStore.setCompanyId(profileData.companyId);
          localStorage.setItem("companyId", profileData.companyId);
        }

        if (profileData.userId) {
          authStore.setUserId(profileData.userId);
        }
      } catch (err: any) {
        if (err.code === "ERR_NETWORK") {
          navigatorAndWindowStore.setIsOnline(false);
        } else if (err.hasOwnProperty("response")) {
          const errorMsg: string = err.response.data.message;

          if (err.response.status === 500) {
            setCustomErrorMessage(t("sorryWeSeemToHaveEncountered") as string);
          }
          if (errorMsg.includes("user not actived")) {
            setCustomErrorMessage(t("usernameNoLongerValid") as string);
          }
        }
        authStore.setState(StoreState.ERROR);
      }
    }
  };

  const onChangeFormLogin = (e: any) => {
    authStore.setState(StoreState.DONE);
    if (e.target.name === "rememberMe") {
      const { rememberMe } = userInfo;
      setUserInfo({
        ...userInfo,
        rememberMe: !rememberMe,
      });
    } else {
      setUserInfo({
        ...userInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onChangeFormNewPassword = (e: any) => {
    authStore.setState(StoreState.DONE);
    setNewPasswordInfo({ ...newPasswordInfo, [e.target.name]: e.target.value });
  };

  const handleForgotPassword = () => {
    authStore.setFormLoginVisible(false);
    authStore.setFormRecoverPasswordVisible(true);
    authStore.setFormNewPasswordVisible(false);
    authStore.setNewPasswordSavedVisible(false);
    authStore.setState(StoreState.DONE);
    clearErrors();
  };

  const handleGoBackLogin = () => {
    authStore.setFormLoginVisible(true);
    authStore.setFormRecoverPasswordVisible(false);
    authStore.setFormNewPasswordVisible(false);
    authStore.setNewPasswordSavedVisible(false);
    setUserInfo({
      username: "",
      password: "",
      rememberMe: false,
      email: "",
      error: false,
    });
    setNewPasswordInfo({
      verificationCode: "",
      password: "",
    });
    authStore.setState(StoreState.DONE);
    clearErrors();
  };

  const clearErrors = () => {
    setCustomErrorMessage(undefined);
    authStore.setMessageError("");
  };

  return (
    <LoginLayout>
      {authStore.formLoginVisible && (
        <LoginForm
          username={userInfo.username}
          password={userInfo.password}
          error={authStore.state === StoreState.ERROR}
          errorMessage={customErrorMessage}
          rememberMe={userInfo.rememberMe}
          title={t("logInTitle")}
          onSubmit={handleFormLogin}
          onChange={onChangeFormLogin}
          onClickForgotPassword={handleForgotPassword}
          onClickForgotUsername={(e) => console.log(e)}
          isLoading={authStore.state === StoreState.PEDDING}
        />
      )}

      {authStore.formRecoverPasswordVisible && (
        <RecoverPasswordForm
          title={t("resetPassword")}
          email={userInfo.email}
          error={authStore.state === StoreState.ERROR}
          errorMessage={authStore.messageError}
          onSubmit={(e) => {
            e.preventDefault();
            authStore.recoveryUserApi(userInfo.email);
          }}
          onChange={(e) => {
            authStore.setState(StoreState.DONE);
            setUserInfo({
              ...userInfo,
              email: e.target.value,
            });
          }}
          onClickGoBackLogin={handleGoBackLogin}
          isLoading={authStore.state === StoreState.PEDDING}
        />
      )}
      {authStore.formNewPasswordVisible && (
        <NewPasswordForm
          verificationCode={newPasswordInfo.verificationCode}
          password={newPasswordInfo.password}
          error={authStore.state === StoreState.ERROR}
          title={t("verificationCodeSent")}
          text={t("successWeveSentYouEmailPasswordReset")}
          onSubmit={(e) => {
            e.preventDefault();
            authStore.updatePasswordApi(
              authStore.recoveryUserId,
              newPasswordInfo.verificationCode,
              newPasswordInfo.password
            );
          }}
          onChange={onChangeFormNewPassword}
          onClickGoBackLogin={handleGoBackLogin}
          isLoading={authStore.state === StoreState.PEDDING}
        />
      )}
      {authStore.newPasswordSavedVisible && (
        <NewPasswordSaved
          title={t("doneNewPasswordSaved")}
          text={t("successGoBackToLogin")}
          onClick={handleGoBackLogin}
        />
      )}
    </LoginLayout>
  );
};

export default observer(Login);
